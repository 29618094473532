import React from "react";
import { Markdown } from "components";

const Textbox = (props) => {
  const { pageTitle, pageDescription } = props;
  const style = {
    backgroundColor: "#FAF1E5",
    opacity: "85%",
  };
  return (
    <>
      <div
        className="uk-margin uk-margin-auto-left uk-card uk-card-default uk-card-body uk-width-1-3@m"
        style={style}
      >
        <div className="uk-h2 uk-text-dark uk-text-bold">{pageTitle}</div>
        <Markdown data={pageDescription} headingClass="uk-text-dark" />
      </div>
    </>
  );
};

export default Textbox;
