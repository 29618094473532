export const urls = [
  {
    name: "소개",
    url: "/intro",
  },
  {
    name: "산&책",
    url: "/sanchek",
  },
  {
    name: "화반",
    url: "/hwaban",
  },
  {
    name: "카페소리",
    url: "/cafesori",
  },
  {
    name: "가든",
    url: "/garden",
  },
  {
    name: "전시",
    url: "/art-collections",
  },
  {
    name: "이벤트",
    url: "/events-overview",
  },
  {
    name: "대관안내",
    url: "/daegwan",
  },
  {
    name: "설악문화재단",
    url: "/foundation",
  },
];
