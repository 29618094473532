/** @format */

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { GrFacebook, GrInstagram } from "react-icons/gr";

function Footer() {
  return (
    <div className="uk-section uk-section-secondary">
      <div className="uk-container">
        <div className="uk-flex">
          <div className="uk-flex uk-flex-column">
            <div className="uk-flex uk-margin-bottom">
              <div className="uk-text uk-text-white uk-margin-right">
                <a href="/announcements">공지사항</a>
              </div>
              <div className="uk-text uk-text-white uk-margin-right">
                <a href="/press-releases">보도자료</a>
              </div>
              <div className="uk-text uk-text-white">
                <a href="https://www.sorakcablecar.co.kr/">설악케이블카</a>
              </div>
            </div>
            <div className="uk-flex uk-flex-column uk-margin-auto-top">
              <p className="uk-h6 uk-text-white uk-margin-remove">
                강원도 속초시 관광로 439 (노학동 980-26)
              </p>
              <p className="uk-h6 uk-text-white uk-margin-remove">
                033 ) 638 - 4002
              </p>
            </div>
            <div className="uk-flex uk-flex-row uk-margin-top">
              <a
                href="https://www.facebook.com/sorakculturalcenter/"
                target="_blank"
              >
                <GrFacebook className="uk-margin-right" />
              </a>
              <a href="https://www.instagram.com/_cafesori_/" target="_blank">
                <GrInstagram className="uk-margin-right" />
              </a>
              <a href="https://www.instagram.com/_hwaban_/" target="_blank">
                <GrInstagram />
              </a>
            </div>
          </div>

          <div className="uk-flex uk-margin-auto-left uk-padding-small">
            <a href="/">
              <StaticImage
                src="../images/sorakcc_logo_4.png"
                alt="SorakCC"
                placeholder="blurred"
                layout="fixed"
                width={200}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
