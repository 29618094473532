import React from 'react';
import Markdown from 'react-markdown';

const CustomParagraph = ({ children, className }) => {
  return <div className={className ? className : 'uk-p'}>{children}</div>;
};

const CustomList = ({ children }) => {
  return <div className='uk-p'>{children}</div>;
};

const CustomHeading = ({ level, className, children }) => {
  return <div className={`uk-h${level} ${className}`}>{children}</div>;
};

const CustomMarkdown = (props) => {
  const { data, headingClass, paragraphClass } = props;
  return (
    <Markdown
      source={data}
      escapeHtml={false}
      renderers={{
        list: (props) => {
          return <CustomList {...props} />;
        },
        heading: (props) => {
          return <CustomHeading {...props} className={headingClass} />;
        },
        paragraph: (props) => {
          return <CustomParagraph {...props} className={paragraphClass} />;
        },
      }}
    />
  );
};

export default CustomMarkdown;
