import React from "react";

const FilterDropDown = (props) => {
  const { filterList, onClick } = props;
  const _filter = filterList?.map((filter, index) => {
    return (
      <li
        className="uk-width-1-3 uk-margin-small-bottom"
        onClick={() => onClick(filter)}
        key={index}
      >
        {filter}
      </li>
    );
  });
  return (
    <>
      <button class="uk-button uk-button-default" type="button">
        <div className="uk-text-nowrap">필터</div>
      </button>
      <div class="uk-width-medium" uk-dropdown="pos: top-right; mode: click">
        <ul class="uk-nav uk-dropdown-nav">
          <div className="uk-grid">
            <li
              className="uk-width-1-3 uk-margin-small-bottom"
              onClick={() => onClick("")}
            >
              전체
            </li>
            {_filter}
          </div>
        </ul>
      </div>
    </>
  );
};

export default FilterDropDown;
