import React from "react";

const Section = ({ title, subtitle, children, center }) => {
  let _className =
    "uk-container uk-container-large uk-flex uk-flex-column uk-margin-large-bottom";
  if (center) {
    _className = _className.concat(" uk-flex-middle uk-flex-center");
  }

  return (
    <div className="uk-section uk-align-center" style={{ maxWidth: "1440px" }}>
      {title ? (
        <div className={_className}>
          <div className="uk-h1 uk-text-semi-bold uk-margin-remove">
            {title}
          </div>
        </div>
      ) : null}
      {subtitle ? (
        <div className="uk-container uk-margin-large-bottom">
          <div className="uk-h4 uk-margin-small-top">{subtitle}</div>
        </div>
      ) : null}
      <div className="uk-container">{children}</div>
    </div>
  );
};

export default Section;
