import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";

const EventCard = (props) => {
  const { event, right } = props;

  const _img = (
    <div className="uk-width-1-2@m">
      <Link to={`/event/${event.slug}`} className="uk-link-reset">
        <GatsbyImage
          image={event.image.localFile.childImageSharp.gatsbyImageData}
          className="uk-box-shadow-large"
          alt=""
        />
      </Link>
    </div>
  );
  return (
    <div
      className="uk-grid uk-grid-match"
      uk-scrollspy="cls: uk-animation-fade"
    >
      {right ? _img : null}
      <div className="uk-width-1-2@m">
        <div className="uk-flex uk-flex-middle">
          <div className="uk-flex uk-flex-column">
            <Link to={`/event/${event.slug}`} className="uk-link-reset">
              <div className="uk-h2 uk-text-semi-bold">{event.title}</div>
              <Markdown source={event.short_description} escapeHtml={false} />
            </Link>
          </div>
        </div>
      </div>
      {right ? null : _img}
    </div>
  );
};

export default EventCard;
