import React from "react";

const Filter = (props) => {
  const { filterList, onClick } = props;
  const _filter = filterList?.map((filter, index) => {
    return (
      <button
        className="uk-button uk-button-default uk-width-expand uk-margin-small-bottom"
        onClick={() => onClick(filter)}
        key={index}
      >
        {filter}
      </button>
    );
  });
  return (
    <>
      <button
        className="uk-button uk-button-default uk-width-expand uk-margin-small-bottom"
        onClick={() => onClick("")}
      >
        전체
      </button>
      {_filter}
    </>
  );
};

export default Filter;
