import React from "react";
import { EventCard } from "components";

const EventCardList = (props) => {
  const { events } = props;

  const _eventlist = events.nodes.map((event, index) => {
    return <EventCard event={event} right={index % 2 === 0} key />;
  });

  return <div>{_eventlist}</div>;
};

export default EventCardList;
