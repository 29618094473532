import React from "react";
import Moment from "react-moment";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Event = (props) => {
  const { data, rows } = props;

  const formatDate = <Moment format="MM-DD-YYYY">{data.date}</Moment>;

  return (
    <div className={`uk-width-1-${rows}@m uk-width-1-2@s`}>
      <div className="uk-flex uk-flex-column uk-flex-middle">
        <div className="uk-h5 uk-text-bold uk-margin-bottom">
          {data.location.title}
        </div>
        <Link to={`/event/${data.slug}`} className="uk-link-reset">
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            className="uk-box-shadow-medium"
            alt=""
          />
        </Link>
        <div className="uk-h5 uk-text-bold uk-margin-small-top uk-margin-remove-bottom">
          {formatDate}
        </div>
        <div className="uk-h6 uk-margin-small-top uk-margin-large-bottom">
          {data.title}
        </div>
      </div>
    </div>
  );
};

export default Event;
