/** @format */

import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";

const EventCard = (props) => {
  const { data } = props;
  const { art, title, artist, date, description, medium, size } = data;
  const _image = art ? art.localFile.childImageSharp.gatsbyImageData : null;
  return (
    <>
      <div
        className="uk-grid uk-grid-match"
        uk-scrollspy="cls: uk-animation-fade"
      >
        <div>
          <GatsbyImage
            image={_image}
            alt=""
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className="uk-flex uk-flex-column uk-margin-top">
          <hr className="uk-margin-top uk-width-1-6" />
          <div className="uk-h2">{artist}</div>
          <div className="uk-margin-remove uk-h4">{title}</div>
          <div className="uk-margin-remove-top uk-h5">{date}</div>
          <div className="uk-margin-remove uk-h5">{medium}</div>
          <div className="uk-margin-remove-top uk-h5">{size}</div>
          <div className="uk-width-1-1">{description}</div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
