import React from 'react';

const Modal = (props) => {
  const { title, description, children } = props;
  return (
    <div id='modal' className='uk-modal-container uk-flex-top' data-uk-modal>
      <div className='uk-modal-dialog uk-modal-body uk-margin-auto-vertical'>
        <button
          className='uk-modal-close-default'
          type='button'
          data-uk-close
        ></button>
        <div className='uk-h2'>{title}</div>
        <p>{description}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
