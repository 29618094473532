import React, { useState, useEffect } from 'react';
import { NavBar } from 'react-uikit3';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { urls } from 'utils/urls';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { IconContext } from 'react-icons';
import { FaTimes } from 'react-icons/fa';
import { VscMenu } from 'react-icons/vsc';

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' },
};

const MenuButton = (props) => {
  const { onClick } = props;
  return (
    <IconContext.Provider value={{ color: 'black' }}>
      <motion.button
        className='uk-button uk-button-link'
        onClick={onClick}
        whileHover={{ scale: 1.2 }}
        transition={{ type: 'spring', stiffness: '300' }}
      >
        <div className='uk-padding-small uk-margin-right'>
          <VscMenu />
        </div>
      </motion.button>
    </IconContext.Provider>
  );
};

const Navbar = (props) => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { changeColorOnScroll } = props;

  const { scrollYProgress } = useViewportScroll();
  const scaleAnim = useTransform(scrollYProgress, [0, 0.04], [1.6, 0.9]);
  const yPosAnim = useTransform(scrollYProgress, [0, 0.04], [40, 0]);
  const xPosAnim = useTransform(scrollYProgress, [0, 0.04], [70, 0]);
  let animateTitle = false;
  if (typeof window != 'undefined') {
    animateTitle = window.innerWidth > 960;
  }

  useEffect(() => {
    setIsModalOpen(false);
  }, [location.pathname]);

  const toggleModal = (open) => {
    setIsModalOpen(open);
  };

  React.useEffect(() => {
    if (typeof window != 'undefined') {
      if (props.changeColorOnScroll) {
        window.addEventListener('scroll', headerColorChange);
      }
      return function cleanup() {
        if (props.changeColorOnScroll) {
          window.removeEventListener('scroll', headerColorChange);
        }
      };
    }
  });

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName('nav')[0]
        .classList.remove('uk-navbar-transparent');
      document.body.getElementsByTagName('nav')[0].classList.add('uk-sticky');
      document.body
        .getElementsByTagName('nav')[0]
        .classList.add('uk-navbar-primary');
      document.body
        .getElementsByTagName('nav')[0]
        .classList.add('uk-box-shadow-small');
    } else {
      document.body
        .getElementsByTagName('nav')[0]
        .classList.add('uk-navbar-transparent');
      document.body
        .getElementsByTagName('nav')[0]
        .classList.remove('uk-sticky');
      document.body
        .getElementsByTagName('nav')[0]
        .classList.remove('uk-navbar-primary');
      document.body
        .getElementsByTagName('nav')[0]
        .classList.remove('uk-box-shadow-small');
    }
  };

  const modalMenu = urls.map((url, index) => (
    <motion.li
      whileHover={{ scale: 1.5 }}
      transition={{ type: 'spring', stiffness: '300' }}
      key={index}
    >
      <Link to={url.url}>
        <div
          className={`uk-text-menu uk-text-bold uk-text-center uk-padding-small`}
          style={{
            border: `${
              location.pathname === url.url ? '1px solid #000000' : 'none'
            }`,
            color: 'black',
          }}
        >
          {url.name}
        </div>
      </Link>
    </motion.li>
  ));

  const _items = urls.map((url, index) => {
    return (
      <li
        className={`${location.pathname === url.url ? `uk-text-bolder` : ''}`}
        key={index}
      >
        <Link className='uk-text-secondary uk-margin-small-right' to={url.url}>
          {url.name}
        </Link>
      </li>
    );
  });

  return (
    <div className='nav-height'>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          zIndex: 980,
        }}
      >
        <NavBar className='uk-navbar-transparent uk-max-height-80'>
          <div className='uk-navbar-left'>
            <div className='uk-position-relative uk-position-z-index uk-margin-small-top uk-margin-small-left'>
              <Link to={'/'}>
                <motion.div
                  style={
                    animateTitle
                      ? {
                          scale: scaleAnim,
                          y: yPosAnim,
                          x: xPosAnim,
                        }
                      : {}
                  }
                >
                  <StaticImage
                    src='../images/sorakcc_logo.png'
                    alt='SorakCC'
                    className='logo'
                    placeholder='blurred'
                    layout='constrained'
                    loading='eager'
                    width={300}
                  />
                </motion.div>
              </Link>
            </div>
          </div>
          <div className='uk-navbar-right uk-visible@m'>
            <ul className='uk-navbar-nav'>{_items}</ul>
          </div>
          <div className='uk-navbar-right uk-hidden@m'>
            <ul className='uk-navbar-nav'>
              <li>
                <MenuButton onClick={() => toggleModal(true)} />
              </li>
            </ul>
          </div>
        </NavBar>
        {isModalOpen ? (
          <motion.div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 990,
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'white',
            }}
            animate={isModalOpen ? 'open' : 'closed'}
            variants={variants}
          >
            <div className='uk-flex uk-width-expand uk-flex-column uk-height-1-1 uk-flex-middle uk-flex-center'>
              <ul className='uk-nav uk-navbar-dropdown-nav uk-hidden@m'>
                {modalMenu}
              </ul>
              <button
                className='uk-button uk-button-link uk-text-center uk-padding-small uk-margin-right uk-margin-top'
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                }}
                onClick={() => toggleModal(false)}
              >
                <IconContext.Provider value={{ color: 'black' }}>
                  <motion.div
                    whileHover={{ scale: 2 }}
                    transition={{ type: 'spring', stiffness: '300' }}
                  >
                    <FaTimes />
                  </motion.div>
                </IconContext.Provider>
              </button>
            </div>
          </motion.div>
        ) : null}
      </div>
    </div>
  );
};
export default Navbar;
