import React from "react";
import Moment from "react-moment";
import { Link } from "gatsby";

const EventCover = (props) => {
  const { event } = props;
  const { title, date, slug } = event;
  const formatDate = <Moment format="MM-DD-YYYY">{date}</Moment>;

  return (
    <div
      className="uk-width-1-4@m uk-width-1-2@s uk-margin-bottom"
      uk-scrollspy="cls: uk-animation-fade"
    >
      <div className="uk-flex uk-flex-column uk-flex-middle">
        <Link to={`/event/${slug}`} className="uk-link-reset">
          <img
            src={event.image.url}
            className="event uk-box-shadow-medium"
            alt=""
          />
        </Link>
        <div className="uk-h5 uk-text-bold uk-margin-small-top uk-margin-remove-bottom">
          {formatDate}
        </div>
        <div className="uk-h6 uk-margin-small-top uk-margin-large-bottom">
          {title}
        </div>
      </div>
    </div>
  );
};

export default EventCover;
