import React from 'react';
import { Link } from 'gatsby';

const BookCover = (props) => {
  const { book } = props;
  const { title, author, slug } = book;

  return (
    <div
      className='uk-width-1-4@m uk-width-1-2@s uk-margin-bottom'
      uk-scrollspy='cls: uk-animation-fade'
    >
      <div className='uk-flex uk-flex-column uk-flex-middle'>
        <Link to={`/book-detail/${slug}`} className='uk-link-reset'>
          <img
            src={book.image.url}
            className='book uk-box-shadow-medium'
            alt=''
          />
        </Link>
        <div className='uk-h5 uk-text-bold uk-margin-small-top uk-margin-remove-bottom'>
          {title}
        </div>
        <div className='uk-h6 uk-margin-small-top uk-margin-large-bottom'>
          {author}
        </div>
      </div>
    </div>
  );
};

export default BookCover;
