import React from "react";
import { Textbox } from "./";
import { GatsbyImage } from "gatsby-plugin-image";

function Carousel(props) {
  const { imgList, pageTitle, pageDescription } = props;
  const _imgList = imgList?.map((img, index) => {
    return (
      <li key={index}>
        <div
          className={`uk-overlay uk-position-bottom`}
          style={{ zIndex: 1000 }}
        >
          {pageTitle || pageDescription ? (
            <div className="uk-visible@m">
              <Textbox
                pageTitle={pageTitle}
                pageDescription={pageDescription}
              />
            </div>
          ) : null}
        </div>
        <GatsbyImage
          image={
            img.localFile ? img.localFile.childImageSharp.gatsbyImageData : img
          }
          alt=""
          style={{ height: "100%" }}
          imgStyle={{ objectFit: "cover" }}
        />
      </li>
    );
  });

  return (
    <div uk-slideshow="animation: push; autoplay: true; ratio: 2.5:1; autoplay-interval: 5000; min-height: 400; max-height: 800">
      <div
        className="uk-position-relative uk-visible-toggle uk-light"
        tabIndex="-1"
      >
        <ul className="uk-slideshow-items">{_imgList}</ul>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
  );
}

export default Carousel;
