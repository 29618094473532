import React from "react";

const CoverList = (props) => {
  const { component: Component, data, rows } = props;

  const _compList = data.slice(0, 6).map((e, index) => {
    return <Component data={e} key={index} rows={rows} />;
  });

  return (
    <>
      <div uk-scrollspy="cls: uk-animation-fade">{_compList}</div>
    </>
  );
};

export default CoverList;
